import { FC, ReactNode } from "react";
import classNames from "classnames";

import { AnyAll, Maybe } from "constants/types";
import { ApproverModeLabel } from ".";

interface StepDescriptionProps {
  children?: ReactNode;
  title: string;
  /** In the absence of operator, assume to be initator */
  operator?: AnyAll;
  numApprovers?: number;
  isApproved?: Maybe<boolean>;
}

/**
 * This one comes with the title, operator, separator and current step indicator
 * Renders the step approvers as children.
 */
const StepDescription: FC<StepDescriptionProps> = ({
  title,
  operator,
  numApprovers = 0,
  children,
}) => {
  return (
    <>
      <h4 className="text-center text-t5 font-medium">{title}</h4>
      <p
        className={classNames("text-center text-t5", { invisible: !operator })}
      >
        {operator ? (
          <ApproverModeLabel
            numApprovers={numApprovers}
            approverOperator={operator}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </p>
      {children}
    </>
  );
};

export default StepDescription;
