import classNames from "classnames";

const CardItem = ({ title, value }: { title: string; value: any }) => (
  <p className="mt-16 flex text-t4">
    <span className="w-[104px] text-grey-100">{title}</span>{" "}
    <span
      className={classNames("ml-16 flex-1", {
        truncate: typeof value === "string",
      })}
    >
      {value}
    </span>
  </p>
);

export default CardItem;
