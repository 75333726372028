import { FC, ReactNode, useEffect } from "react";
import classNames from "classnames";
import { Check, X } from "react-feather";
import ReactTooltip from "react-tooltip";

import { StepApproverInstancesConnection } from "constants/types";
import CurrentStep from "./CurrentStep";

interface CircleProps {
  className?: string;
  children?: ReactNode;
}

const Circle: FC<CircleProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={classNames(
        "flex h-32 w-32 items-center justify-center rounded-full",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

interface StepCircleProps {
  stepApprovers: StepApproverInstancesConnection;
  isStepApproved?: boolean | null;
  isCurrentStep?: boolean;
}

const StepCircle: FC<StepCircleProps> = ({
  isCurrentStep,
  stepApprovers,
  isStepApproved,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [stepApprovers]);

  if (isCurrentStep) {
    return <CurrentStep stepApprovers={stepApprovers} />;
  }
  if (isStepApproved === true) {
    return (
      <Circle className="bg-green-100 text-green--50">
        <Check />
      </Circle>
    );
  }
  if (isStepApproved === false) {
    return (
      <Circle className="bg-red-25 text-red-100">
        <X />
      </Circle>
    );
  }
  return <Circle className="bg-grey-25" aria-label="Pending Step" />;
};

export default StepCircle;
