import { FC, useMemo } from "react";

import { StepApproverInstancesConnection } from "constants/types";

interface CurrentStepProps {
  stepApprovers: StepApproverInstancesConnection;
}

const CurrentStep: FC<CurrentStepProps> = ({ stepApprovers }) => {
  const approvedPercentage = useMemo(() => {
    const { nodes } = stepApprovers;
    const approved = nodes.filter((node) => node?.isApproved === true);
    const total = nodes.length;
    return (approved.length / total) * 100;
  }, [stepApprovers]);
  return (
    <svg viewBox="0 0 32 32" className="h-32 w-32 -rotate-90 rounded-full">
      <circle
        r="16"
        cx="16"
        cy="16"
        className="fill-blue-100 stroke-green-100"
        strokeWidth={32}
        strokeDasharray={`${approvedPercentage} 100`}
      />
    </svg>
  );
};

export default CurrentStep;
