import { FC, ReactNode } from "react";
import classNames from "classnames";

import { AnyAll, Maybe } from "constants/types";

interface StepDescriptionWrapperProps {
  children?: ReactNode;
  operator?: AnyAll;
  isCurrentStep?: boolean;
  isApproved?: Maybe<boolean>;
}

/**
 * Different for the larger full version of Approval Path
 */
const StepDescriptionWrapper: FC<StepDescriptionWrapperProps> = ({
  isCurrentStep,
  operator,
  isApproved,
  children,
}) => {
  return (
    <div
      className={classNames(
        "relative flex flex-col items-center justify-center gap-y-4 rounded-xlarge bg-dark-blue-10 p-12",
        {
          "border-2 border-blue-100": isCurrentStep,
          "-before:translate-x-1/2 before:absolute before:top-1/2 before:right-[calc(100%_+_7px)] before:block before:h-2 before:w-32 before:transform":
            operator,
        },
        isApproved == undefined ? "before:bg-grey-25" : "before:bg-green-100"
      )}
    >
      {children}
    </div>
  );
};

export default StepDescriptionWrapper;
