import { FC } from "react";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";

import { FlowInstance } from "constants/types";
import {
  StepApprovers,
  useApprovalPath,
  StepDescription,
} from "components/Request/ApprovalPath";
import StepCircle from "./StepCircle";

interface ApprovalPathProps {
  flowInstance: FlowInstance;
  className?: string;
  isCentered?: boolean;
}

/**
 * Path represented in tables in profiles
 */
const ApprovalPath: FC<ApprovalPathProps> = ({
  flowInstance,
  className,
  isCentered = false,
}) => {
  const pathSteps = useApprovalPath({ flowInstance });

  return (
    <div className={classNames("flex gap-x-24 overflow-x-auto", className)}>
      {pathSteps.map((step, index) => {
        if (!step) return;
        return (
          <div
            key={step.id}
            className={classNames([
              "relative flex gap-x-1",
              "-before:translate-x-1/2 before:absolute before:top-1/2 before:right-[calc(100%_+_7px)] before:block before:h-2 before:w-8 before:transform before:bg-green-100 first:before:invisible",
              step.isApproved == undefined
                ? "before:bg-grey-25"
                : "before:bg-green-100",
              {
                // https://stackoverflow.com/a/33455342
                "mr-auto": isCentered && index === pathSteps.length - 1,
                "ml-auto": isCentered && index === 0,
              },
            ])}
            data-for="common"
            data-html={true}
            data-tip={ReactDOMServer.renderToStaticMarkup(
              <div className="relative flex flex-col items-center justify-center gap-y-4">
                <StepDescription
                  key={step.id}
                  title={step.name}
                  operator={step.approverOperator}
                  numApprovers={step.stepApproverInstances.nodes.length}
                  isApproved={step.isApproved}
                >
                  <StepApprovers
                    stepApprovers={step.stepApproverInstances}
                    isStepApproved={step.isApproved}
                  />
                </StepDescription>
              </div>
            )}
          >
            <StepCircle
              stepApprovers={step.stepApproverInstances}
              isStepApproved={step.isApproved}
              isCurrentStep={flowInstance.currentStepInstance?.id === step.id}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ApprovalPath;
