import PaginationSelector, {
  PaginationSelectorProps,
} from "components/PaginationSelector";
import LoadingIndicator from "components/LoadingIndicator";
import classNames from "classnames";
import { Maybe } from "constants/types";
import { ReactNode } from "react";

export interface Data {
  id: string;
}

export type DataColumn = Maybe<Data>;

type PaginationTableProps<T> = {
  loading: boolean;
  className?: string;
  data: Array<Maybe<T>>;
  renderCard: Function;
  emptyMessage?: ReactNode;
} & PaginationSelectorProps;

const PaginationCards = <T extends DataColumn>({
  loading,
  data,
  pageCount,
  currentPage,
  className,
  setCurrentPage,
  renderCard,
  emptyMessage = (
    <div className="flex h-full items-center justify-center">
      <p>No items</p>
    </div>
  ),
}: PaginationTableProps<T>) => {
  return (
    <div
      className={classNames([
        "flex h-[calc(100%_-_24px)] flex-col bg-white sm:h-full",
        className,
      ])}
    >
      <div className="relative mt-[10px] flex-grow overflow-y-auto">
        {loading ? (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {data.map((item) => renderCard({ item }))}
            {data.length === 0 && emptyMessage}
          </>
        )}
      </div>
      {pageCount > 1 && (
        <PaginationSelector
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default PaginationCards;
