import { useEffect, useState } from "react";

import { FlowInstance, Maybe, StepInstance } from "constants/types";

type CheckConditionProps = {
  conditionValue: string;
  operator: string;
  parameterValue: string;
};

const checkCondition = ({
  conditionValue,
  operator,
  parameterValue,
}: CheckConditionProps) => {
  const conditionNumber = parseFloat(conditionValue);
  const parameterNumber = parseFloat(parameterValue);

  if (operator === ">") return conditionNumber > parameterNumber;
  if (operator === "<") return conditionNumber < parameterNumber;
  if (operator === "is") return conditionValue !== parameterValue;
  if (operator === "is not") return conditionValue === parameterValue;
  return false;
};

const getApprovalPath = (
  stepInstances: Array<Maybe<StepInstance>>,
  startStepInstanceId: string
) => {
  const getStep = (targetStepId: string) =>
    stepInstances?.find((step) => step?.id === targetStepId);

  const pathSteps: Array<StepInstance> = [];

  type AddPathSteps = (stepId: string) => void;

  const addPathStep: AddPathSteps = (stepId: string) => {
    const {
      id,
      name,
      approveStepInstanceId,
      rejectStepInstanceId,
      stepApproverInstances,
      stepConditionInstance,
      approverOperator,
      isApproved,
    } = getStep(stepId) || {
      id: stepId,
      name: "",
      approveStepInstanceId: "",
      rejectStepInstanceId: "",
    };

    const isSkipped =
      !!stepConditionInstance?.id &&
      checkCondition({
        conditionValue: stepConditionInstance.value,
        operator: stepConditionInstance.operator,
        parameterValue:
          stepConditionInstance.flowParameterInstance?.value || "",
      });

    !isSkipped &&
      pathSteps.push({
        id,
        name,
        approveStepInstanceId,
        rejectStepInstanceId,
        stepApproverInstances,
        stepConditionInstance,
        approverOperator,
        isApproved,
      } as StepInstance);

    if (isApproved === false) {
      rejectStepInstanceId && addPathStep(rejectStepInstanceId);
    } else if (approveStepInstanceId) {
      addPathStep(approveStepInstanceId);
    }
  };
  addPathStep(startStepInstanceId);
  return pathSteps;
};

function useApprovalPath({ flowInstance }: { flowInstance: FlowInstance }) {
  const [pathSteps, setPathSteps] = useState<StepInstance[]>([]);

  useEffect(() => {
    if (flowInstance?.stepInstances.nodes) {
      const approvalPath = getApprovalPath(
        flowInstance.stepInstances.nodes,
        flowInstance.startStepInstanceId
      );
      setPathSteps(approvalPath);
    }
  }, [flowInstance]);

  return pathSteps;
}

export default useApprovalPath;
