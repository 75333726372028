import { FC, useEffect } from "react";
import Link from "next/link";
import ReactTooltip from "react-tooltip";
import Image from "next/image";
import { getNameAndTitleCombined } from "utils/user";
import { Maybe } from "constants/types";
import { ApprovalStatus } from "constants/strings";
import Avatar from "components/People/Avatar";

interface ApproverCellProps {
  approver: {
    id: string;
    name: string;
    avatarUrl?: Maybe<string>;
    title?: Maybe<string>;
  };
  approvalStatus?: ApprovalStatus;
}

const ApprovalStatusIcons = {
  [ApprovalStatus.APPROVED]: {
    icon: <Image src="/icons/check.svg" alt="" height="20px" width="20px" />,
  },
  [ApprovalStatus.REJECTED]: {
    icon: <Image src="/icons/x.svg" alt="" height="20px" width="20px" />,
  },
  [ApprovalStatus.PENDING]: {
    icon: <Image src="/icons/clock.svg" alt="" height="20px" width="20px" />,
  },
  [ApprovalStatus.NONE]: {
    icon: null,
  },
};

const ApproverCell: FC<ApproverCellProps> = ({ approver, approvalStatus }) => {
  useEffect(() => {
    // https://github.com/wwayne/react-tooltip#3-tooltip-not-binding-to-dynamic-content
    ReactTooltip.rebuild();
  }, [approver.name]);

  return (
    <Link href={`/people/${approver.id}`}>
      <a
        data-for="common"
        data-tip={getNameAndTitleCombined({
          name: approver.name,
          title: approver.title,
        })}
        className="relative flex gap-x-8 text-t4"
        title={approver.name}
      >
        {approver.name && (
          <Avatar
            name={approver.name}
            avatarUrl={approver.avatarUrl}
            imageSize={48}
            className="inline"
            isRounded
          />
        )}
        {approvalStatus && (
          <div className="h-20 absolute bottom-0 right-[1.5px] rounded-full">
            {ApprovalStatusIcons[approvalStatus].icon}
          </div>
        )}
      </a>
    </Link>
  );
};

export default ApproverCell;
