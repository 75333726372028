import { FC, ReactNode } from "react";
import classNames from "classnames";

import { Maybe } from "constants/types";

type ColumnProps = {
  children?: ReactNode;
  className?: string;
  isFirst: boolean;
};

const Column: FC<ColumnProps> = ({ className, isFirst, children }) => (
  <span
    className={classNames(
      "h-[40px] py-4 px-16 leading-8 text-grey--50",
      className,
      !isFirst && "border-l-2 border-l-grey-15"
    )}
  >
    {children}
  </span>
);

export interface Data {
  id: string;
}

export type DataColumn = Maybe<Data>;

type ColumnData<T> = {
  id: string;
  header: string;
  getCell?: (r: T) => ReactNode;
  isHidden?: () => boolean;
};

export type TableProps<T> = {
  columns: Array<Maybe<ColumnData<T>>>;
  data: Array<Maybe<T>>;
  emptyMessage?: ReactNode;
  style?: React.CSSProperties;
};

const Table = <T extends DataColumn>({
  columns: allColumns,
  data,
  style,
  emptyMessage = (
    <div className="flex h-full items-center justify-center">
      <p>No items</p>
    </div>
  ),
}: TableProps<T>) => {
  const columns = allColumns.filter((column) =>
    column?.isHidden ? !column.isHidden() : true
  );

  const isEmpty = data.length === 0;

  return (
    <div className="h-full w-full">
      <div
        className={classNames(
          "relative grid min-w-full overflow-auto bg-white text-t4",
          {
            "h-full": !isEmpty,
            "h-[60px] overflow-x-hidden": isEmpty,
          }
        )}
        style={{
          gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
          ...style,
        }}
      >
        {columns.map((column, colIndex) => {
          if (!column) {
            return;
          }
          return (
            <div key={column.header} className="flex flex-col">
              <Column
                isFirst={colIndex === 0}
                className="sticky top-0 bg-white font-medium text-grey--50"
              >
                {column.header}
              </Column>
              {data &&
                data.map((row, index) => {
                  if (!row) {
                    return null;
                  }
                  const defaultGetCell = (row: T) => {
                    if (!row) return;
                    if (!column) return;
                    const id = column.id;
                    if (row.hasOwnProperty(id)) {
                      return row[id as keyof Data] ?? null;
                    }
                  };
                  if (!column) return;
                  const { id, getCell = defaultGetCell } = column;
                  return (
                    <Column
                      key={`${row.id}${id}`}
                      isFirst={colIndex === 0}
                      className={classNames({
                        "bg-grey-5": index % 2 === 0,
                      })}
                    >
                      {getCell(row)}
                    </Column>
                  );
                })}
            </div>
          );
        })}
      </div>
      {isEmpty && emptyMessage}
    </div>
  );
};

export default Table;
