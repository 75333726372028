import { FC } from "react";

import {
  StepApproverInstance,
  StepApproverInstancesConnection,
  StepApproversConnection,
} from "constants/types";
import ApproverCell from "./ApproverCell";
import { ApprovalStatus } from "constants/strings";
import { User } from "react-feather";

interface StepApproversProps {
  stepApprovers: StepApproverInstancesConnection | StepApproversConnection;
  isStepApproved?: boolean | null;
}

const StepApprovers: FC<StepApproversProps> = ({
  stepApprovers,
  isStepApproved,
}) => {
  if (stepApprovers?.nodes.length === 0) {
    // This user does not have a manager
    return (
      <div
        title="Manager"
        className="flex h-32 w-32 items-center justify-center rounded-full bg-dark-blue-10"
      >
        <User size={16} />
      </div>
    );
  }
  return (
    <div className="flex gap-x-4 overflow-hidden">
      {stepApprovers?.nodes.map((stepApprover) => {
        if (!stepApprover) return null;
        const { approver } = stepApprover;
        if (!approver) return null;
        const getApprovalStatus = () => {
          const { isApproved } = stepApprover as StepApproverInstance;
          if (isApproved === true) return ApprovalStatus.APPROVED;
          if (isApproved === false) return ApprovalStatus.REJECTED;
          if (isStepApproved || isStepApproved === false)
            return ApprovalStatus.NONE;
          return ApprovalStatus.PENDING;
        };
        return (
          <div key={stepApprover.id} className="flex items-center">
            <ApproverCell
              approver={approver}
              approvalStatus={getApprovalStatus()}
            />
          </div>
        );
      })}
    </div>
  );
};

export default StepApprovers;
