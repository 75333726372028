import { Maybe } from "constants/types";

// helper function generally used to show name and title inside tooltips
const getNameAndTitleCombined = ({
  name,
  title,
}: {
  name: string;
  title: Maybe<string> | undefined;
}) => {
  const titleString = title ? `, ${title}` : "";
  return `${name} ${titleString}`;
};

export { getNameAndTitleCombined };
