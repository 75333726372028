import { FC } from "react";
import classNames from "classnames";
import { FlowInstance } from "constants/types";
import StepDescription from "./StepDescription";
import StepApprovers from "./StepApprovers";
import ApproverCell from "./ApproverCell";
import useApprovalPath from "./useApprovalPath";
import StepDescriptionWrapper from "./StepDescriptionWrapper";

interface ApprovalPathProps {
  flowInstance: FlowInstance;
  className?: string;
}

const ApprovalPath: FC<ApprovalPathProps> = ({ flowInstance, className }) => {
  const pathSteps = useApprovalPath({ flowInstance });

  return (
    <div className={classNames("flex gap-x-48", className)}>
      {flowInstance.initiator && (
        <StepDescriptionWrapper>
          <StepDescription title="Initiator">
            <ApproverCell approver={flowInstance.initiator} />
          </StepDescription>
        </StepDescriptionWrapper>
      )}
      {pathSteps?.map((step) => {
        if (!step) return null;
        return (
          <StepDescriptionWrapper
            key={step.id}
            operator={step.approverOperator}
            isApproved={step.isApproved}
            isCurrentStep={flowInstance.currentStepInstanceId === step.id}
          >
            <StepDescription
              title={step.name}
              operator={step.approverOperator}
              numApprovers={step.stepApproverInstances.nodes.length}
              isApproved={step.isApproved}
            >
              <StepApprovers
                stepApprovers={step.stepApproverInstances}
                isStepApproved={step.isApproved}
              />
            </StepDescription>
          </StepDescriptionWrapper>
        );
      })}
    </div>
  );
};

export default ApprovalPath;
