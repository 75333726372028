import classNames from "classnames";
import { ChevronLeft, ChevronRight } from "react-feather";

export type PaginationSelectorProps = {
  pageCount: number;
  currentPage: number;
  setCurrentPage: (n: number) => void;
};

const MAX_PAGES = 9;

const PaginationSelector: React.FC<PaginationSelectorProps> = ({
  pageCount,
  currentPage,
  setCurrentPage,
}) => {
  const numbers = (start: number, end: number) => {
    const numberElements = [];
    for (let i = start; i <= end; i++) {
      numberElements.push(
        <button
          key={i}
          className={classNames(
            "inline-block cursor-pointer select-none px-8 py-4 text-t3 font-medium text-grey--50",
            { ["bg-dark-blue-10"]: currentPage === i }
          )}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }
    return numberElements;
  };

  const splitNumbers = () => {
    if (currentPage < MAX_PAGES - 3) {
      return [
        ...numbers(1, MAX_PAGES - 2),
        "...",
        ...numbers(pageCount, pageCount),
      ];
    }

    if (currentPage < pageCount - (MAX_PAGES - 5)) {
      return [
        ...numbers(1, 1),
        "...",
        ...numbers(currentPage - 2, currentPage + 2),
        "...",
        ...numbers(pageCount, pageCount),
      ];
    }

    return [
      ...numbers(1, 1),
      "...",
      ...numbers(pageCount - (MAX_PAGES - 3), pageCount),
    ];
  };

  const isLeftDisabled = currentPage <= 1;
  const isRightDisabled = currentPage >= pageCount;

  return (
    <div className="flex items-center justify-center py-8">
      <button
        className={classNames(
          "inline-block",
          isLeftDisabled && "cursor-not-allowed text-grey-75"
        )}
        disabled={isLeftDisabled}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <ChevronLeft size={16} />
      </button>
      {pageCount <= MAX_PAGES ? numbers(1, pageCount) : splitNumbers()}
      <button
        className={classNames(
          "inline-block",
          isRightDisabled && "cursor-not-allowed text-grey-50"
        )}
        disabled={isRightDisabled}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <ChevronRight size={16} />
      </button>
    </div>
  );
};

export default PaginationSelector;
