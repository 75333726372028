import { FC } from "react";

import { AnyAll } from "constants/types";
import { ApproverModeLabels } from "constants/strings";

interface ApproverModeLabelProps {
  numApprovers: number;
  approverOperator: AnyAll;
}

const ApproverModeLabel: FC<ApproverModeLabelProps> = ({
  numApprovers,
  approverOperator,
}) => {
  return (
    <>
      {numApprovers === 1
        ? "Only:"
        : `${ApproverModeLabels[approverOperator]}:`}
    </>
  );
};

export default ApproverModeLabel;
