import { FlowInstance } from "constants/types";
import Link from "next/link";
import Avatar from "components/People/Avatar";

const InitiatorItem = ({ flowInstance }: { flowInstance: FlowInstance }) => {
  const { initiator } = flowInstance;
  return (
    <div className="my-16 flex w-full items-center text-t4">
      <span className="w-[104px] text-grey-100">Initiator</span>
      {initiator && (
        <span className="ml-16 w-[calc(100%_-_90px)] flex-1">
          <Link href={`/people/${initiator?.id}`}>
            <a className="group flex w-full">
              {initiator.name && (
                <Avatar
                  name={initiator.name}
                  imageSize={32}
                  avatarUrl={initiator.avatarUrl}
                  isRounded
                />
              )}
              <div className="flex w-[calc(100%_-_32px)] flex-col justify-center">
                <span className="ml-8 w-full truncate group-hover:underline">
                  {initiator?.name}
                </span>
                {initiator?.title && (
                  <span className="ml-8 inline-block w-full flex-1 truncate text-grey-100 group-hover:underline">
                    {initiator?.title}
                  </span>
                )}
              </div>
            </a>
          </Link>
        </span>
      )}
    </div>
  );
};

export default InitiatorItem;
