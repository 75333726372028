import { FlowInstance } from "constants/types";
import classNames from "classnames";
import Link from "next/link";
import { ReactChild } from "react";

const Card = ({
  flowInstance,
  children,
  className,
}: {
  flowInstance: FlowInstance;
  className?: string;
  children?: ReactChild | Array<ReactChild>;
}) => {
  return (
    <div
      className={classNames(
        "mx-auto mb-16 flex max-w-[359px] flex-col items-center rounded-[16px] bg-dark-blue-10 p-16 font-medium text-grey--50",
        className
      )}
    >
      <Link href={`/requests/${flowInstance.id}`} passHref>
        <a className="mb-16 hover:underline"> {flowInstance.name}</a>
      </Link>
      <div className="w-full">{children}</div>
    </div>
  );
};

export default Card;
