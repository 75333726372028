import PaginationSelector, {
  PaginationSelectorProps,
} from "components/PaginationSelector";
import LoadingIndicator from "components/LoadingIndicator";
import Table, { DataColumn, TableProps } from "components/Table";
import classNames from "classnames";

type PaginationTableProps<T> = {
  loading: boolean;
  className?: string;
} & PaginationSelectorProps &
  TableProps<T>;

const PaginationTable = <T extends DataColumn>({
  loading,
  data,
  columns,
  pageCount,
  currentPage,
  setCurrentPage,
  className,
  emptyMessage,
  style,
}: PaginationTableProps<T>) => {
  return (
    <div className={classNames(["flex h-full flex-col bg-white", className])}>
      <div className="flex h-[calc(100%_-_48px)] items-center justify-center">
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            style={style}
            data={data}
            columns={columns}
            emptyMessage={emptyMessage}
          />
        )}
      </div>
      {pageCount > 1 && (
        <PaginationSelector
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default PaginationTable;
